import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import minus from '../../assets/images/pricing/minus.png';
import plus from '../../assets/images/pricing/plus.png';

export default function PriceBox(props) {
    const path = props.page;

    const generateBoxClassName = () => {
        if (props.active.id === props.subType.id) return "single-pricing-box active";
        if ((props.subType === "free" && props.userSubType !== "free")
            || (props.subType.type === "Standard" && props.userSubType.type === "Premium"))
            return "single-pricing-box disabled";
        else return "single-pricing-box";
    }

    const generateBtnClassName = () => {
        if ((props.subType.id === props.userSubType.id) ||
            (props.subType.type === "Standard" && props.userSubType.type === "Premium")) {
            return "default-btn disabled";
        } else return "default-btn";
    }

    const handleBoxClick = () => {
        if (path !== "/membership-levels") {
            if (props.active.id === props.subType.id) return;
            if ((props.subType === "free" && props.userSubType !== "free")
                || (props.subType.type === "Standard" && props.userSubType.type === "Premium"))
                return;
            else props.onClick();
        }
    }

    const handleBtnClick = (e) => {
        if (props.loggedin) {
            if (props.subType.id !== props.userSubType.id) {
                e.stopPropagation();
                props.buttonAction();
            }
        } else {
            navigate("/")
        }
    }

    return (
        <Col lg={props.colSize} md={props.colSize} className="" key={props.idx}>
            <div onClick={handleBoxClick} className={generateBoxClassName()}>
                <div className="pricing-header">
                    <h3>{props.title}</h3>
                </div>
                <div className="subtitle">
                    <p>{props.subTitle}</p>
                </div>

                <div className="price">
                    {props.subType !== "free" ?
                        <>{props.price} <span>HUF</span> <br></br> <span className="monthly">{props.priceRecurrence}</span></>
                        : <span>Ingyenes</span>
                    }
                </div>
                <div className="subtext">
                    {props.subType !== "free" &&
                        <p>{props.subText}</p>
                    }
                    {props.subType === "free" && props.daysLeft > 0 &&
                        <span>{props.daysLeft} nap van hátra</span>}

                    {props.subType === "free" && props.daysLeft <= 0 &&
                        <span>Lejárt</span>}
                </div>

                <ul className="pricing-features">
                    {props.options !== "" && props.options.map(option => {
                        return (
                            <li><img src={option.iconText.includes('minus') ? minus : plus} />{option.text}</li>
                        );
                    })}
                </ul>

                {props.subType !== "free" &&
                    <div className="btn-box">
                        <button className={generateBtnClassName()} onClick={handleBtnClick}>
                            {props.subType.id !== props.userSubType.id ? props.buttonText : "ELŐFIZETVE"}
                            <span></span>
                        </button>
                    </div>
                }

                {path === "/membership-levels" &&
                    <div className="btn-box">
                        <button className="default-btn" onClick={handleBtnClick}>
                            {props.buttonText}
                            <span></span>
                        </button>
                    </div>
                }
            </div>
        </Col>
    );
}