import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from "gatsby";

export default function Information() {

    const query = graphql` {
        strapiPricingInformation {
            title
            informationFeatures {
                informationText
            }
        }
    }`

    const data = useStaticQuery(query);

    return(
        <Container className="informations-container">
            <div className="section-title">
                    <h2>{data.strapiPricingInformation.title}</h2>
                </div>
            <Row className="information-list">
                <Col>
                    {data.strapiPricingInformation.informationFeatures.map((feature, index) => {
                        return(
                            <li>{feature.informationText}</li>
                        );

                    })}
                </Col>
            </Row>
        </Container>
    );
}