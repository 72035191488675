import React from 'react';
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Pricing from '../components/Index/Pricing';
import Qualities from '../components/Index/Qualities';
import Information from '../components/Index/Informations';


export default function MembershipLevels() {

    return (
        <Layout>
            <Navbar />
            <div className='placeholder-area' />
            <div className="membership-levels-area ptb-100">
                <Pricing buttonText='Regisztrálj ingyen!' />
            </div>
            <Qualities />
            <Information />
            <Footer />
        </Layout>
    )
};