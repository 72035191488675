import React from 'react'
import { Row, Container, Modal } from 'react-bootstrap';
import PriceBox from '../Pricing/PriceBox';
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';

export default function Pricing(props) {

    const query = graphql` {
        strapiPricing {
            detail
            title
            priceFeatures {
                information
                price
                priceRecurrence
                title
                packageInformation
                pacakgeDetails {
                    text
                    iconText
                }
            }
        }
    }`

    const data = useStaticQuery(query);

    return (
        <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
            <Container className="">
                <div className="section-title">
                    <h2>{data.strapiPricing.title}</h2>
                    <ReactMarkdown children={data.strapiPricing.detail} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                </div>

                <Row className="">
                    {data.strapiPricing.priceFeatures.map((feature, idx) => {
                        return (
                            <PriceBox
                                page={'/membership-levels'}
                                colSize={6}
                                title={feature.title}
                                subTitle={feature.information}
                                price={feature.price}
                                priceRecurrence={feature.priceRecurrence}
                                subText={feature.packageInformation}
                                options={feature.pacakgeDetails}
                                loggedin={props.login}
                                buttonAction={props.buttonAction}
                                buttonText={props.buttonText}
                                linkPath="/"
                                active="free"
                                subType="free"
                            />

                        );
                    })}
                </Row>
            </Container>
        </section>
    )
}
