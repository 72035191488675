import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
{
    strapiSpeakForUs {
        infoBox {
          title
          unit
          value
        }
      }
}`

function Qualities() {
    const [contentLoaded, setContentLoaded] = useState(false);
    const data = useStaticQuery(query);

    const createScript = () => {
        let script = document.createElement("script");
        script.src = "https://cdn.trustindex.io/loader.js?4b99c1462f391789bc565a5209";
        script.async = true;
        script.defer = true;

        const widgetContainer = document.getElementById("widget-container");
        widgetContainer.appendChild(script);
    }

    useEffect(() => {
        if (!contentLoaded) {
            createScript();
            setContentLoaded(true);
        }
    })

    return (
        <section className="qualities-area">
            <div className="container">
                <div className="section-title">
                    <h2>Mellettünk szól…</h2>
                </div>
                <Row id="widget-container" />
            </div>
        </section>
    )
}

export default Qualities